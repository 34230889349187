// Todo 视图类 - 处理UI渲染和事件处理
export class TodoView {
    constructor(todoService) {
        this.todoService = todoService;
        this.initializeElements();
        this.bindEvents();
        this.currentFilter = 'all';
    }

    initializeElements() {
        this.form = document.getElementById('todo-form');
        this.input = document.getElementById('todo-input');
        this.prioritySelect = document.getElementById('priority-select');
        this.filterSelect = document.getElementById('filter-priority');
        this.list = document.getElementById('todo-list');
    }

    bindEvents() {
        this.form.addEventListener('submit', this.handleSubmit.bind(this));
        this.filterSelect.addEventListener('change', (e) => {
            this.currentFilter = e.target.value;
            this.render();
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const text = this.input.value.trim();
        const priority = this.prioritySelect.value;
        
        if (text) {
            const todo = { 
                id: Date.now(), 
                text, 
                completed: false,
                priority 
            };
            this.todoService.addTodo(todo);
            this.input.value = '';
            this.render();
        }
    }

    createPrioritySelect(todo) {
        const select = document.createElement('select');
        select.className = 'priority-select';
        
        ['high', 'normal', 'low'].forEach(priority => {
            const option = document.createElement('option');
            option.value = priority;
            option.textContent = priority.charAt(0).toUpperCase() + priority.slice(1);
            option.selected = todo.priority === priority;
            select.appendChild(option);
        });

        select.addEventListener('change', (e) => {
            this.todoService.updatePriority(todo.id, e.target.value);
            this.render();
        });

        return select;
    }

    createTodoElement(todo) {
        const li = document.createElement('li');
        li.className = `priority-${todo.priority}`;
        
        const todoContent = document.createElement('div');
        todoContent.className = 'todo-content';
        
        const todoText = document.createElement('span');
        todoText.textContent = todo.text;
        todoText.className = 'todo-text';
        if (todo.completed) {
            todoText.classList.add('completed');
        }

        const prioritySelect = this.createPrioritySelect(todo);
        
        const deleteBtn = document.createElement('button');
        deleteBtn.textContent = 'Delete';
        deleteBtn.className = 'delete-btn';

        deleteBtn.addEventListener('click', () => {
            this.todoService.deleteTodo(todo.id);
            this.render();
        });

        todoText.addEventListener('click', () => {
            this.todoService.toggleTodo(todo.id);
            this.render();
        });

        todoContent.appendChild(todoText);
        todoContent.appendChild(prioritySelect);
        todoContent.appendChild(deleteBtn);
        li.appendChild(todoContent);
        
        return li;
    }

    render() {
        this.list.innerHTML = '';
        let todos;
        
        if (this.currentFilter === 'all') {
            todos = this.todoService.getSortedTodos();
        } else {
            todos = this.todoService.getTodosByPriority(this.currentFilter);
        }

        todos.forEach(todo => {
            const todoElement = this.createTodoElement(todo);
            this.list.appendChild(todoElement);
        });
    }
}
