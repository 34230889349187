// Todo 服务类 - 处理数据存储和检索
export class TodoService {
    constructor() {
        this.STORAGE_KEY = 'todos';
    }

    getTodos() {
        const todos = localStorage.getItem(this.STORAGE_KEY);
        return todos ? JSON.parse(todos) : [];
    }

    saveTodos(todos) {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(todos));
    }

    addTodo(todo) {
        const todos = this.getTodos();
        todos.push(todo);
        this.saveTodos(todos);
    }

    deleteTodo(todoId) {
        const todos = this.getTodos();
        const filteredTodos = todos.filter(todo => todo.id !== todoId);
        this.saveTodos(filteredTodos);
    }

    toggleTodo(todoId) {
        const todos = this.getTodos();
        const todo = todos.find(t => t.id === todoId);
        if (todo) {
            todo.completed = !todo.completed;
            this.saveTodos(todos);
        }
    }

    updatePriority(todoId, priority) {
        const todos = this.getTodos();
        const todo = todos.find(t => t.id === todoId);
        if (todo) {
            todo.priority = priority;
            this.saveTodos(todos);
        }
    }

    // 根据优先级获取待办事项
    getTodosByPriority(priority) {
        const todos = this.getTodos();
        return todos.filter(todo => todo.priority === priority);
    }

    // 获取已排序的待办事项（按优先级排序）
    getSortedTodos() {
        const todos = this.getTodos();
        const priorityOrder = { high: 1, normal: 2, low: 3 };
        
        return todos.sort((a, b) => {
            // 首先按优先级排序
            const priorityDiff = priorityOrder[a.priority] - priorityOrder[b.priority];
            if (priorityDiff !== 0) return priorityDiff;
            
            // 如果优先级相同，按创建时间排序
            return a.id - b.id;
        });
    }
}
