import { TodoService } from './services/TodoService.js';
import { TodoView } from './views/TodoView.js';

// 主应用类
class App {
    constructor() {
        this.todoService = new TodoService();
        this.todoView = new TodoView(this.todoService);
    }

    init() {
        this.todoView.render();
    }
}

// 等待DOM加载完成后初始化应用
document.addEventListener('DOMContentLoaded', () => {
    const app = new App();
    app.init();
});
